













































import useUser from "@/use/user";
import {
  computed,
  defineComponent,
  reactive,
  ref,
} from "@vue/composition-api";

export default defineComponent({
  components: {
    MCompanyFilter: () => import("@/components/molecules/m-company-filter.vue"),
    MInventoryItems: () =>
      import("@/components/molecules/m-inventory-items.vue"),
  },
  setup(_, { root }) {
    const { hasAccessTo, hasManyCompanies } = useUser({ root })

    const refs = {
      occupied: ref(),
      available: ref(),
    }

    const model = reactive({
      company: null,
      search: computed({
        get: () => root.$route.query['accessories.search'] as string,
        set: (value) => root.$router.replace({ query: { ...root.$route.query, 'accessories.search': value || undefined } }).catch(() => [])
      }),
    });

    const groupByCompany = computed(
      () => (hasAccessTo.value("employee") || hasManyCompanies.value()) && !(model.company || null)
    );

    const refresh = () => {
      Object.values(refs).forEach(item => {
        if (item.value && 'refresh' in item.value) item.value.refresh()
      });
    }

    return {
      hasAccessTo,
      hasManyCompanies,

      model,
      groupByCompany,

      refresh,
      ...refs
    };
  },
});
